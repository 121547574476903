import React, { Component, Fragment } from 'react'
import './App.css'
import Webcam from 'react-webcam'
import { TweenMax } from 'gsap'
import Whammy from 'react-whammy'

// import Faceapi from 'face-api.js'


// import PageWefie from './images/page-wefie.png'
// import WefieOverlay from './images/wefie-overlay.png'
import homeBtn from './images/home button.png'
import frameOne from './images/frame-1.png'
import frameTwo from './images/frame-2.png'
import frameThree from './images/frame-3.png'
import frameFour from './images/frame-4.png'
import frameFive from './images/frame-5.png'
import WefieGifOverlay from './images/wefie-gif-frame.png'
import gifFrameTwo from './images/gif-frame-2.png'
import gifFrameThree from './images/gif-frame-3.png'
import gifFrameFour from './images/gif-frame-4.png'
import videoBg from './images/video_bg.png'
// import proceedOne from './images/proceed-one.png'
// import thankOne from './images/thankyouone.png'
import DeleteImg from './images/delete-btn.png'
import AgreeTick from './images/agree-tick.png'
import AgreeCross from './images/agree-cross.png'
import Smile from './images/smile.png'
// import FaceDetect from './images/face-detect.png'
import chickenOne from './images/chicken-1.svg'
import chickenTwo from './images/chicken-2.svg'
import chickenThree from './images/chicken-3.svg'
import chickenFour from './images/chicken-4.svg'
import nuggetOne from './images/nugget-1.svg'
import nuggetTwo from './images/nugget-2.svg'
import nuggetThree from './images/nugget-3.svg'
import nuggetFour from './images/nugget-4.svg'
import nuggetFive from './images/nugget-5.svg'
import sound from './images/shutter.mp3'
import bgMusic from './images/bgmusic.wav'
import look from './images/look.png'
import Loading from './images/loading.png'

class App extends Component {
   constructor() {
      super();
      this.state = {
         page: 'Home',
         camera_counter: 5,
         is_activation: false,
         image_array: [],
         base_image: [],
         video_src: null,
         image_frame: frameFour,
         a: 0,
         addClass: false,
         is_detecting:false,
         number_of_people : 0,
         selfieId: null,
         emails: ['','','','','','',''],
         agrees: [false, false, false, false, false, false],
         email_valids: [true, true, true, true, true, true],
         caret: 0,
         input_no: 0,
         max_people:0,
         sound_src:null,
         toHomeTimer:0,
         bgMusic_src:null,
         lock_no_of_ppl: false,
         agree_failed: false,
         email_failed: false,
         is_test: false,
      };
      // this.emails = []
      this.ref0 = React.createRef()
      this.ref1 = React.createRef()
      this.audio=new Audio(bgMusic)
      this.audio.play()
   }
   time = 0;

   componentDidMount() {
      document.addEventListener('contextmenu', event => event.preventDefault());
      const urlParams = new URLSearchParams(window.location.search);
      let activation = urlParams.get("activation")
      if (activation) {
         console.log('is activation')
         this.setState({ is_activation: true })
      }
      let is_test = urlParams.get('testing')
      if(is_test) {
         this.setState({ is_test: true })
      }
      this.setState({bgMusic_src:bgMusic})
      let _this = this
      setInterval( () => {
         if(this.state.page != 'Home'){
            console.log(this.time)
            this.time = this.time + 1
            if(this.time > 30){
               console.log('restarting')
               this.toReStart()
               this.time = 0
            }
         }
      }, 1000)

      const script = document.createElement('script')
      script.src = 'https://unpkg.com/@tensorflow/tfjs'
      script.id = 'tensorflow'
      document.body.appendChild(script)

      script.onload = () => {
         const script1 = document.createElement('script')
         script1.src = 'https://unpkg.com/@tensorflow-models/posenet'
         script1.id = 'posenet'
         document.body.appendChild(script1)
         script1.onload = async() => {
            this.net = await window.posenet.load()
         }
      }
      const script2 = document.createElement('script')
      script2.src = 'https://wefie.wunderfauks.com/wp/wp-content/themes/twentynineteen-child/js/gif.js'
      script2.id = 'gifjs'
      document.body.appendChild(script2)
      script2.onload = async() => {

      }

   }
   startDetect = async() => {
      // let self = this
      const imageScaleFactor = 1;
      const flipHorizontal = true;
      const outputStride = 16;
      const imageElement = document.getElementById('webcam');
      let screenshot = this.camera.getScreenshot()
      let c = document.createElement('canvas')
      let ctx = c.getContext('2d')
      let image = new Image()
      image.onload = async() => {
         c.width = image.width
         c.height = image.height
         ctx.drawImage(image, 0, 50, 1350, 1500, 100, 50, 1350, 1500)
         try {
            const pose = await this.net.estimateMultiplePoses(imageElement, imageScaleFactor, flipHorizontal, outputStride);

            let number_of_people = 0
            if(pose.length > 0) {
               for (var i = 0; i < pose.length; i++) {
                  if(pose[i].score > 0.3) {
                     number_of_people++
                  }
               }
            }
            if(!this.state.lock_no_of_ppl && number_of_people > this.state.number_of_people ) {
               this.setState({ number_of_people:number_of_people })
            }

            if(this.state.max_people>1){
               this.setState({toHomeTimer:0})
            }
            if(number_of_people>this.state.max_people){
               this.setState({max_people:number_of_people})

               if(number_of_people > 1){
                  this.setState({ lock_no_of_ppl : true })
                  this.time = 0
                  setTimeout( () => {
                     document.getElementById('wefie-counter').style.display='block';
                     document.getElementById('face').style.display='none';
                     this.setState({image_frame:frameOne})
                     this.takePhoto1()
                  }, 10000)
               }

               this.setState({ number_of_people })

            }

            if(this.state.is_detecting){
               setTimeout( () => {
                  this.startDetect()
               }, 200)
            }

         }catch(e) {
            setTimeout( () => { this.startDetect() }, 100)
         }
      }
      image.src = screenshot


   }
   handleChangeNextPage = (a, b, page = '', click = '') => {
      TweenMax.to(a, 0.3, { left: '-100%', opacity: 1, display: 'none' });
      TweenMax.to(b, 0.3, { left: '0%', display: 'block' });
      this.handleEvent(`${click} Button Click`, this.state.page);
      this.setState({ page: page });
      this.time = 0;
   }

   handleChangePrevPage = (a, b, page = '', click = '') => {
      TweenMax.to(a, 0.3, { left: '+100%', display: 'none' });
      TweenMax.fromTo(b,0.3,{ opacity: 0 },{ left: '0%', opacity: 1, display: 'block' });
      if(page != '') {
         this.handleEvent(`${click} Button Click`, this.state.page);
         this.setState({ page: page });
      }
      this.time = 0;
   }
   handleChangeNextPageTwo = (pages) => {
      TweenMax.set(pages, { left: '100%' });
   }
   handleEvent = (label, page, extra = '') => {
      let data = new FormData()
      data.append("action", 'update_count')
      data.append("label", label)
      data.append("frame", page + ' Page')
      data.append("add_option", extra)
      fetch("https://wefie.wunderfauks.com/wp/wp-admin/admin-ajax.php", {
         method: "POST",
         body: data,
      })

   }

   onBegin = () => {
      this.setTimeout=(()=>{
         this.setState({condition:!this.state.condition})
      },1000)
      // this.handleChangeNextPage('#page-welcome, #page-welcome-a', '#page-instruction, #page-instruction-a', 'Instruction')
      this.handleChangeNextPage('#page-welcome, #page-welcome-a', '#page-tnc, #page-tnc-a', 'Tnc', 'Touch to begin')
   }
   onInstruction = () => {
      this.handleChangeNextPage('#page-tnc, #page-tnc-a', '#page-instruction, #page-instruction-a', 'Instruction', 'I Agree')
   }
   toReStart = (pages) => {
      document.getElementById('face').style.display='block';
      document.getElementById('popup').style.display="block"
      this.handleChangePrevPage('#page-tnc, #page-tnc-a,#page-instruction, #page-instruction-a, #page-wefie, #page-wefie-a, #page-gif, #page-gif-a, #page-proceed-one, #page-proceed-one-a, #page-thank-one, #page-thank-one-a', '#page-welcome, #page-welcome-a','Home')
      this.handleChangeNextPageTwo(".pages")
      this.handleEvent(`Restart`, this.state.page);
      this.time = 0
      this.setState({
         camera_counter: 5, page: 'Home', image_array: [], video_src: null, input_no: 0,sound_src:null,is_detecting:false,toHomeTimer:0,max_people: 0, lock_no_of_ppl: false, number_of_people: 0,
         emails: ['','','','','',''], agrees: [false, false, false, false, false, false] ,email_valids: [true, true, true, true, true, true],
         agree_failed: false, email_failed: false,
      })
   }
   onTake = () => {
      document.getElementById('face').style.display='block';
      this.handleChangeNextPage('#page-instruction, #page-instruction-a', '#page-wefie, #page-wefie-a', 'Wefie', 'Take Wefie')
      this.setState({ is_detecting: true })
      this.setState({toHomeTimer:0})
      this.setState({image_frame: frameFour})
      this.time = 20
      setTimeout( () => {
         this.startDetect()
      }, 500)
   }
   reTake = () => {
      document.getElementById('face').style.display='block';
      this.handleChangePrevPage('#page-gif, #page-gif-a', '#page-wefie, #page-wefie-a', 'Wefie', 'Retake')
      this.setState({ camera_counter: 5, lock_no_of_ppl: false })
      this.setState({toHomeTimer:0})
      this.setState({ image_array: [] })
      this.setState({video_src:null})
      this.setState({number_of_people:0})
      this.setState({max_people:0})
      this.setState({ is_detecting: true })
      this.setState({image_frame: frameFour})
      this.time=0
      setTimeout( () => {
         this.startDetect()
      }, 500)
   }
   nextProceed = () => {

      this.handleChangeNextPage('#page-gif, #page-gif-a', '#page-proceed-one, #page-proceed-one-a', 'Gif Preview', 'Proceed')
   }
   toThank = () => {
      let {email_valids, agrees, emails} = this.state
      let exit = false
      this.setState({ email_failed: false, agree_failed: false})

      email_valids.forEach( (ev) => {
         if(ev == false) {
            exit = true
            return
         }
      })
      if(emails[0] == '' && emails[1] == '' && emails[2] == '' && emails[3] == '' && emails[4] == '' && emails[5] == '' ) {
         exit = true
         this.setState({ email_failed: true })
      }

      agrees.forEach( (a, i) => {
         if(a == false && emails[i] != '') {
            exit = true
            this.setState({ agree_failed: true})
            return
         }
      })

      if(!exit) {
         this.handleChangeNextPage('#page-proceed-one, #page-proceed-one-a', '#page-thank-one, #page-thank-one-a', 'Thank You', 'Proceed')
         this.uploadEmail()
      }
   }

   takePhoto1 = () => {

      for (var i = 1; i <= 5; i++) {
         setTimeout(() => {
            let { camera_counter } = this.state
            this.setState({ camera_counter: camera_counter - 1 })
            if (camera_counter === 1) {
               this.shootPhoto()
            }
            else{
               this.shutterSoundOff();
            }
         }, i * 1000)
      }

      this.time = 0
   }

   shootPhoto = () => {
      document.getElementById('wefie-counter').style.display = 'none'
      this.setState({ image_frame: frameTwo})
      const imageSrc = this.camera.getScreenshot()

      let { image_array } = this.state
      image_array.push(imageSrc)

      if (image_array.length === 2) {
         this.setState({ image_frame: frameThree })
         this.shutterSound();

      }
      else if (image_array.length === 3) {
         this.setState({ image_frame: frameFive })
         this.shutterSound();

      }
      else if (image_array.length === 4) {
         this.setState({ image_frame: frameFive })
         this.shutterSound();
      }
      this.setState({ image_array })
      if (this.state.image_array.length <=3) {
         this.setState({ camera_counter: 5 })
         this.takePhoto1()
      }
      else {
         this.makeGif()
         this.handleChangeNextPage('#page-wefie, #page-wefie-a', '#page-gif, #page-gif-a', 'Gif Preview')
         this.setState({is_detecting:false})
      }
      this.time = 0
   }
   shutterSound =()=> {
      this.setState({sound_src:sound})
   }
   shutterSoundOff =() =>{
      this.setState({sound_src:null})
   }
   makeGif = () => {
      let gifjs = new window.GIF({
         workers: 4,
         quality: 2,
         width: 1080,
         height: 1565,
         workerScript: 'https://wefie.wunderfauks.com/wp/wp-content/themes/twentynineteen-child/js/gif.worker.js'
      })

      let _this = this
      gifjs.on('finished', function(output) {
         console.log('finished')
         let video = URL.createObjectURL(output)
         setTimeout( () => {
            _this.setState({ video_src: video })
         }, 1000)
         fetch("https://wefie.wunderfauks.com/wp/wp-admin/admin-ajax.php?action=gif_1",{
            method: "POST",
         })
         .then(response => response.json())
         .then(json => {
            _this.setState({ selfieId: json.selfie })
            setTimeout( () => {
               _this.uploadVideo(output)
            }, 500)
         })
      })

      let base_image = []
      let encoder = new Whammy.Video(2)
      var overlays = [gifFrameTwo, gifFrameThree, gifFrameFour]
      for (var i = 1; i <= 3; i++) {
         let can = document.getElementById("canvas_" + i);
         can.crossOrigin = '*';
         let ctx = can.getContext("2d");
         let col_img = new Image()
         col_img.setAttribute('crossOrigin', 'anonymous');
         col_img.src = this.state.image_array[i]

         let overlay = overlays[i-1]
         col_img.onload = () => {
            ctx.drawImage(col_img, 300, 50, 1000, 1500, 100, 50, 900, 1500)
            let overlay_img = new Image()
            overlay_img.setAttribute('crossOrigin', 'anonymous');
            overlay_img.src = overlay
            overlay_img.onload = () => {
               ctx.drawImage(overlay_img, 0, 0, 1080, 1565)
               let convert_image = can.toDataURL("image/jpeg")
               base_image.push(convert_image)
            }
         }
      }

      setTimeout(() => {
         var a = 0;
         for (var j = 0; j <= 3; j++) {
            let image = new Image()
            image.src = base_image[j]
            image.onload = () => {
               gifjs.addFrame(image, {
                  copy: true
               })
            }
            a++
         }
         if (a === 4) {
            setTimeout(() => {
               gifjs.render()
            }, 800)
         }
      }, 800)
   }

   uploadVideo = async(output) => {
      let reader = new FileReader()
      reader.addEventListener('load', () => {
         let formData = new FormData()
         formData.append("img", reader.result)
         formData.append('selfieID', this.state.selfieId)
         fetch("https://wefie.wunderfauks.com/wp/wp-admin/admin-ajax.php?action=gif_2",{
            method: "POST",
            body: formData
         })

      })
      reader.readAsDataURL(output)

   }

   uploadEmail = async() => {
      let {emails, selfieId} = this.state
      fetch("https://wefie.wunderfauks.com/wp/wp-admin/admin-ajax.php?action=email_submit",{
         method: "POST",
         body: JSON.stringify({
            selfieId, emails
         })
      })

   }

   renderKeyboard = () => {
      const { email, input_no } = this.state

      return(
         <div id="keyboard"  > {/* style={{ top: input_no==0 ? 530 : input_no==1 ? 630 : input_no==2 ? 730 : input_no==3 ? 830 : input_no==4 ? 930 : 1030  }}*/}
         <div className="krows" id="row1">
            <span onClick={(e)=>this.handleKey(e)} data-id="1">1</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="2">2</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="3">3</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="4">4</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="5">5</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="6">6</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="7">7</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="8">8</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="9">9</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="0">0</span>
         </div>
         <div className="krows" id="row2">
            <span onClick={(e)=>this.handleKey(e)} data-id="q">Q</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="w">W</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="e">E</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="r">R</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="t">T</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="y">Y</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="u">U</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="i">I</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="o">O</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="p">P</span>
            <span onClick={()=>this.handleDelete()}> <img src={DeleteImg} width="24" /> </span>
         </div>
         <div className="krows" id="row3">
            <span onClick={(e)=>this.handleKey(e)} data-id="a">A</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="s">S</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="d">D</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="f">F</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="g">G</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="h">H</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="j">J</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="k">K</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="l">L</span>
            <span onClick={()=>this.handleReturn()}>return</span>
         </div>
         <div className="krows" id="row4">
            <span onClick={(e)=>this.handleKey(e)} data-id="z">Z</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="x">X</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="c">C</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="v">V</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="b">B</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="n">N</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="m">M</span>
            <span onClick={(e)=>this.handleKey(e)} data-id=".">.</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="-">-</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="_">_</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="@">@</span>
         </div>
         <div className="krows" id="row5">
            <span onClick={(e)=>this.handleKey(e)} data-id="@gmail.com">@gmail.com</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="@hotmail.com">@hotmail.com</span>
            <span onClick={(e)=>this.handleKey(e)} data-id="@yahoo.com">@yahoo.com</span>
            <span onClick={(e)=>this.handleKey(e)} data-id=".com">.com</span>

         </div>
      </div>
   )
}
handleKey = (e) => {
   this.time = -75
   let {emails, caret, input_no, email_valids} = this.state
   let text = e.currentTarget.dataset.id
   let emailText = ''
   // if(caret === emails[input_no].length ){
   emailText = emails[input_no] + text
   //    this.setState({caret: this.ref0.selectionStart + text.length})
   // }else{
   //    emailText = emails[input_no].slice(0,caret) + text + emails[input_no].slice(caret, emails[input_no].length)
   //    this.setState({caret: caret + text.length})
   // }
   emails[input_no] = emailText
   email_valids[input_no] = this.validateEmail(emailText)

   this.setState({
      emails, email_valids,
   })
   this.time = 0
}
validateEmail = (email) => {
   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase())
}
handleDelete = () => {
   let {emails, caret, input_no, email_valids} = this.state
   let position = caret
   // if(caret <= 0) {
   //    return
   // }
   if(emails[input_no].length <= 0) {
      return
   }
   let emailText = ''
   // if(position === emails[input_no].length ){
   emailText = emails[input_no].slice(0, emails[input_no].length - 1)
   // }else{
   //    emailText =  emails[input_no].slice(0,position-1) + emails[input_no].slice(position, emails[input_no].length)
   // }
   emails[input_no] = emailText
   email_valids[input_no] = this.validateEmail(emailText)
   this.setState({
      emails, email_valids,
      // caret: caret-1
   })
   this.time = 0
}
handleReturn = () => {
   let {input_no} = this.state
   if(input_no < 5) {
      input_no = input_no + 1
   }
   this.setState({ input_no })
}

toggleAgree = (id) => {
   let {agrees} = this.state
   agrees[id] = !agrees[id]
   this.setState({ agrees })
   this.time = 0
}

onFocus = (i) => {
   this.setState({ input_no: i })
   this.time = 0
}
popupOpen =()=>{
   document.getElementById('popup').style.display="block"
}
popupClose =()=>{
   document.getElementById('popup').style.display="none"
}
render() {
   const { is_activation, camera_counter, video_src ,max_people, emails, agrees, email_valids ,number_of_people,sound_src, agree_failed, email_failed} = this.state

   let nop_loop = []
   for (var i = 0; i < max_people; i++) {
      nop_loop.push(i)
   }
   if(nop_loop.length == 0) {
      nop_loop = [0]
   }
   // nop_loop = [0,1,2,3,4,5]
   let nop_loop_two=[]
   for(var j=0;j<number_of_people;j++)
   {
      nop_loop_two.push(j)
   }
   if(nop_loop_two.lenght==0){
      nop_loop_two=[0]
   }

   return (
      <div id="app">
         <div className='pages' id={is_activation ? "page-welcome-a" : 'page-welcome'}>
            <img src={nuggetOne} className="n-1"/>
            <img src={nuggetTwo} className="n-2"/>
            <img src={nuggetThree} className="n-3"/>
            <img src={nuggetFour} className="n-4"/>
            <img src={nuggetFive} className="n-5"/>
            <img src={chickenOne} className="c-1"/>
            <img src={chickenFour} className="c-4"/>
            <img src={chickenTwo} className="c-2"/>
            <img src={chickenThree} className="c-3"/>
            <img src={homeBtn} className="a" id="home-btn"/>
            <div className="hidden-btn" id="touch-to-begin" onClick={this.onBegin} />
         </div>


         <div className='pages' id={is_activation ? 'page-tnc-a' : 'page-tnc'}>
            <div className="hidden-btn" id="inst-btn" onClick={this.onInstruction} />
            <div className="hidden-btn" id="bth-btn" onClick={this.toReStart} />
         </div>

         <div className='pages' id={is_activation ? 'page-instruction-a' : 'page-instruction'}>
            <div className="hidden-btn" id="touch-to-take" onClick={this.onTake} />
            <div className="hidden-btn" id="bth-btn-two" onClick={this.toReStart} />
         </div>

         <div className='pages' id={is_activation ? 'page-wefie-a' : 'page-wefie'}>
            <img src={this.state.image_frame} id="wefie-overlay" />
            <img src={look}  className="look-here"/>
            <Webcam
               ref={r => this.camera = r}
               audio={false}
               screenshotFormat='image/jpeg'
               screenshotQuality={1}
               id="webcam"
            />
            <audio src={sound_src} autoPlay/>
            {/*<img src={PageWefie} id="wefie-bg"/> */}
            {/*<img src={FaceDetect} id="face-detect"/>*/}
            <div id="face">
               {nop_loop_two.length<=1?
                  <Fragment>
                     <p className="face-text">FACE</p>
                  </Fragment>
                  :
                  <Fragment>
                     <p className="face-text-2">FACES</p>
                  </Fragment>
               }
               <Fragment>

                  <p className="face-text-3">DETECTED</p>
               </Fragment>
               {is_activation ?
                  <Fragment>
                     {nop_loop.length == 1?
                        <Fragment>
                           <p className="yellow-1">Grab another buddy<br/>to unlock a deal!</p>
                        </Fragment>
                        : nop_loop.length == 2 ?
                        <Fragment>
                           <p className="yellow-2-1">Happy Sharing Box B<br/>promo unlocked!</p>
                           <p className="yellow-2-2">Get 2 more buddies to<br/>unlock a bigger deal!</p>
                        </Fragment>
                        : nop_loop.length == 3 ?
                        <Fragment>
                           <p className="yellow-2-1">Happy Sharing Box B<br/>promo unlocked!</p>
                           <p className="yellow-2-2">Get 1 more buddies to<br/>unlock a bigger deal!</p>
                        </Fragment>
                        :
                        <Fragment>
                           <p className="yellow-3">Happy Sharing Box A<br/>promo unlocked!</p>
                        </Fragment>
                     }
                  </Fragment>
                  :
                  <Fragment>
                     {nop_loop.length == 1 ?
                        <Fragment>
                           <p className="yellow-1">Get another buddy<br/>to take a wefie!</p>
                        </Fragment>
                        :nop_loop.length>=2?
                        <Fragment>
                           <p className="yellow-1">Get another buddy<br/>to take a wefie!</p>
                        </Fragment>
                        : nop_loop.length == 6 ?
                        <p className="yellow-4">Ready? Smile!</p>
                        :
                        <p className="yellow-1">Get more buddies!<br/>The more the merrier!</p>
                     }
                  </Fragment>
               }

            </div>
            <div id="wefie-counter">{camera_counter}</div>
            <div id="face-count">{number_of_people}</div>

            <canvas id="canvas_0" ref="canvas" width={1080} height={1565} style={{ display: "none" }}></canvas>
            <canvas id="canvas_1" ref="canvas" width={1080} height={1565} style={{ display: "none" }}> </canvas>
            <canvas id="canvas_2" ref="canvas" width={1080} height={1565} style={{ display: "none" }}> </canvas>
            <canvas id="canvas_3" ref="canvas" width={1080} height={1565} style={{ display: "none" }}> </canvas>
            <canvas id="canvas_4" ref="canvas" width={1080} height={1565} style={{ display: "none" }}> </canvas>

            <div className="hidden-btn" id="bth-btn-three" onClick={this.toReStart} />
         </div>
         <div className='pages' id={is_activation ? 'page-gif-a' : 'page-gif'}>
            {/*<img src={PageWefie} id="wefie-bg" />*/}
            {video_src ?
               <img className="video" src={video_src} width={550}  />
               :
               <img src={Loading} id="loading" />
            }
            <img src={videoBg} id="video-bg" />
            <div className="hidden-btn" id="retake-btn" onClick={this.reTake} />
            <div className="hidden-btn" id="proceed-one-btn" onClick={this.nextProceed} />
            <div className="hidden-btn" id="bth-btn-three" onClick={this.toReStart} />

         </div>
         <div className='pages' id={is_activation ? 'page-proceed-one-a' : 'page-proceed-one'}>

            {email_failed ?
               <p className="email-alert">At least one email address is required</p>
               :
               <Fragment>
                  {agree_failed &&
                     <p className="email-alert">please consent to continue</p>
                  }
               </Fragment>
            }

            <div className="hidden-btn" id="proceed-two-btn" onClick={this.toThank} />
            <div className="hidden-btn" id="bth-btn-four" onClick={this.toReStart} />

            {nop_loop.map ( (i) => (
               <div className="inputs" id={`inputs-${i}`} key={i} >
                  <input id={`input-${i}`} value={emails[i]} onFocus={()=>this.onFocus(i)}
                     ref={this[`ref${i}`]} placeholder="name@email.com" className={email_valids[i] ? '' : 'red-inputs' } />
                     <img src={Smile} className="smiles" />
                     <div onClick={()=>this.toggleAgree(i)}>
                        <p className="texts">I confirm that I have read the Terms & Conditions and the Consent above and agree to the terms thereto.</p>
                        <div className="agrees" >
                           {agrees[i] ?
                              <img src={AgreeTick} className="agree-tick" />
                              :
                              <img src={AgreeCross} className="agree-cross" />
                           }
                        </div>
                     </div>
                  </div>
               ))}

               {this.renderKeyboard()}

               <div className="hidden-btn" id="popup-btn" onClick={this.popupOpen} />
               <div className="hover_bkgr_fricc" id="popup">
                  <span className="helper"></span>
                  <div className="popup-text">
                     <div className="popupCloseButton" onClick={this.popupClose}>x</div>
                     <h5>Consent</h5>
                     <p>By participating in this McDelivery National Sharing Day promotion ("Promotion"), I hereby authorise, agree and consent to allow McDonald's to:</p>
                     <p>use the gifs/photographs containing the likeness of myself and any person depicted in the gifs/photographs submitted ("Images") to be, and remain, the sole and exclusive property of Hanbaobao Pte. Ltd. ("McDonald's") and I hereby assign any proprietary right I may have acquired in or to such Images to McDonald's to deal with in its sole discretion.</p>
                     <p>(b) collect, use, disclose and/or process personal data that I now provide McDonald's and I hereby declare and warrant that I have the authority and consent of the persons depicted in the Images to provide their personal data to McDonald's for the purpose of (i) selecting the gifs/photographs taken; (ii) emailing any selected Images to the email address provided to McDonald's; (iii) posting the selected Images on the internet or any website or publication for advertising, promotion, and publicity of the Promotion (collectively the "Purposes");</p>
                     <p>(c) disclose personal data provided by me to McDonald's third party service providers and agents ("Representatives") that are engaged by McDonald's to perform certain functions in connection with the Purposes, so long as disclosure is necessary to enable such Representatives to perform the said functions. In this regard, I hereby acknowledge, agree and consent that the said Representatives may, to the extent necessary to perform the said functions, collect, use, disclose and/or process such personal data.</p>
                     <p>I confirm that I am at least 18 years of age, or that if I am below 18 years of age, my parent/legal guardian has given their consent to the aforesaid.</p>
                     <p>I hereby represent and warrant that I am the user of the email address provided by me below, and that I have read and understood the above provisions.</p>

                  </div>
               </div>
            </div>
            <div className='pages' id={is_activation ? 'page-thank-one-a' : 'page-thank-one'}>
               <div className="hidden-btn" id="to-home" onClick={this.toReStart} />
            </div>
         </div>
      )
   }
}
export default App;
